<template>
  <div v-if="$store.state.fastPermissoes.ativo == 'S'">
    <!-- fd-platform-header -->
    <header class="fd-app-platform-header">
      <div
        v-if="$store.state.fastPlataforma.banner_plataforma"
        :style="{ 'background-image': 'url(' + $store.state.fastPlataforma.banner_plataforma + ')', backgroundColor: '#353535' }"
        class="fd-app-platform-header-bg"
      />
      <div class="fd-app-platform-header-overlay">
        <div class="container">
          <h1 class="position-relative">
            {{ $store.state.fastPlataforma.nome_plataforma }}
          </h1>
        </div>
      </div>
    </header>
    <!-- /fd-platform-header -->
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                href="#"
                class="aluno_font_color"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
        <!-- fd-modes -->
        <section class="fd-modes">
          <div class="container">
            <div class="row mt-4">
              <div
                v-if="$store.state.fastPermissoes.aluno == 'S' || $store.state.fastPermissoes.professor == 'S' || $store.state.fastPermissoes.administrador == 'S'"
                class="col-sm-12 col-md-12 col-lg-4 mb-4"
              >
                <div class="card">
                  <div class="col-12 text-center mt-4">
                    <img
                      :src="require('@/assets/images/graduates.png')"
                      alt="Imagem do modo aluno"
                      style="height: 128px"
                    >
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">
                      Modo aluno
                    </h5>
                    <p class="card-text">
                      No modo aluno você terá acesso à visão que o aluno possui da sua plataforma.
                    </p>
                    <a
                      :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                      class="btn btn-mode-admin btn-block"
                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
                    >Entrar</a>
                  </div>
                </div>
              </div>
              <div
                v-if="$store.state.fastPermissoes.professor == 'S' || $store.state.fastPermissoes.administrador == 'S'"
                class="col-sm-12 col-md-12 col-lg-4 mb-4"
              >
                <div class="card">
                  <div class="col-12 text-center mt-4">
                    <img
                      :src="require('@/assets/images/teacher.png')"
                      alt="Imagem do modo professor"
                      style="height: 128px"
                    >
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">
                      Modo professor
                    </h5>
                    <p class="card-text">
                      No modo professor você terá acesso ao gerenciamento de cursos e lives.
                    </p>
                    <a
                      :href="'/plataforma/' + $route.params.id_plataforma + '/professor'"
                      class="btn btn-mode-admin btn-block"
                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
                    >Entrar</a>
                  </div>
                </div>
              </div>
              <div
                v-if="$store.state.fastPermissoes.administrador == 'S'"
                class="col-sm-12 col-md-12 col-lg-4 mb-4"
              >
                <div class="card">
                  <div class="col-12 text-center mt-4">
                    <img
                      :src="require('@/assets/images/admin.png')"
                      alt="Imagem do modo administrador"
                      style="height: 128px"
                    >
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">
                      Modo administrador
                    </h5>
                    <p class="card-text">
                      No modo administrador você terá acesso a todos os recursos da sua plataforma.
                    </p>
                    <a
                      :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                      class="btn btn-mode-admin btn-block"
                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                    >Entrar</a>
                  </div>
                </div>
              </div>
              <div
                v-if="$store.state.fastPermissoes.secretario == 'S' || $store.state.fastPermissoes.administrador == 'S'"
                class="col-sm-12 col-md-12 col-lg-4 mb-4"
              >
                <div class="card">
                  <div class="col-12 text-center mt-4">
                    <img
                      :src="require('@/assets/images/secretary.png')"
                      alt="Imagem do modo secretaria"
                      style="height: 128px"
                    >
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">
                      Modo secretaria
                    </h5>
                    <p class="card-text">
                      No modo secretaria você terá acesso ao módulo de secretaria.
                    </p>
                    <a
                      v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
                      :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                      class="btn btn-mode-admin btn-block"
                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                    >Entrar</a>
                    <a
                      v-else
                      href="#"
                      style="cursor: not-allowed; background: rgb(154 154 154)"
                      class="btn btn-mode-admin btn-block"
                      @click.prevent="exibeToasty('Módulo não disponível para sua plataforma', 'error')"
                    >Entrar</a>
                  </div>
                </div>
              </div>
              <div
                v-if="$store.state.fastPermissoes.tesouraria == 'S' || $store.state.fastPermissoes.administrador == 'S'"
                class="col-sm-12 col-md-12 col-lg-4 mb-4"
              >
                <div class="card">
                  <div class="col-12 text-center mt-4">
                    <img
                      :src="require('@/assets/images/economic.png')"
                      alt="Imagem do modo financeiro"
                      style="height: 128px"
                    >
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">
                      Modo financeiro
                    </h5>
                    <p class="card-text">
                      No modo financeiro você terá acesso ao módulo de tesouraria.
                    </p>
                    <a
                      v-if="$store.state.fastPlataformaModulos.modulo_secretaria"
                      :href="'/plataforma/' + $route.params.id_plataforma + '/tesouraria'"
                      class="btn btn-mode-admin btn-block"
                      @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/tesouraria')"
                    >Entrar</a>
                    <a
                      v-else
                      href="#"
                      style="cursor: not-allowed; background: rgb(154 154 154)"
                      class="btn btn-mode-admin btn-block"
                      @click.prevent="exibeToasty('Módulo não disponível para sua plataforma', 'error')"
                    >Entrar</a>
                  </div>
                </div>
              </div>
              <div
                v-if="$store.state.fastPermissoes.responsavel == 'S' || $store.state.fastPermissoes.administrador == 'S'"
                class="col-sm-12 col-md-12 col-lg-4 mb-4"
              >
                <div class="card">
                  <div class="col-12 text-center mt-4">
                    <img
                      :src="require('@/assets/images/family.png')"
                      alt="Imagem do modo responsável"
                      style="height: 128px"
                    >
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">
                      Modo responsável
                    </h5>
                    <p class="card-text">
                      No modo responsável você poderá acompanhar estatísticas do aluno.
                    </p>
                    <a
                      href="#"
                      style="cursor: not-allowed; background: rgb(154 154 154)"
                      class="btn btn-mode-admin btn-block"
                      @click.prevent="exibeToasty('Acesso não autorizado', 'error')"
                    >Entrar</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4 pc">
              <a
                class="btn btn-red-hollow"
                href="/minhas-plataformas"
                @click.prevent="$router.push('/minhas-plataformas')"
              >
                <b-icon-arrow-return-left /> Voltar
              </a>
            </div>
          </div>
        </section>
        <!-- /fd-modes -->
      </div>
    </div>
    <!-- /fd-wrap -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoPlataforma",
  mixins: [methods],
  data: function () {
    return {
      n_cursos: "",
      n_cursos_publicados: "",
      n_professores: "",
    };
  },
  mounted: function () {
    this.$toast.clear();
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      // Busca informações da plataforma
      this.getPlataformaData(this.$route.params.id_plataforma)
        .then(() => {
          // Busca permissões da plataforma
          return this.getPlataformaPermissoesData(
            this.$route.params.id_plataforma
          );
        })
        .then(() => {
          console.log("this.$store.state.fastPermissoes", this.$store.state.fastPermissoes)
          // Usuário não é ativo?
          if (this.$store.state.fastPermissoes.ativo != "S") {
            window.location.href = "/minhas-plataformas";
            return false;
          } else {
            // Usuário é aluno? Redireciona direto
            if (this.$store.state.fastPermissoes.aluno == "S" && this.$store.state.fastPermissoes.administrador != "S") {
              window.location.href = "/plataforma/" + this.$route.params.id_plataforma + "/aluno";
              return false;
            }
          }
        })
        .then(() => {
          return this.getFastPersonalizacao(this.$route.params.id_plataforma);
        })
        .then(() => {
          return this.getFastPlataformaModulos(this.$route.params.id_plataforma)
        })
        .then((e) => {
          // Aplica fonte
          Array.from(document.querySelectorAll(".aluno_font_color")).forEach(
            (e) =>
              (e.style.color = this.$store.state.fastPersonalizacao.aluno_font_color)
          );
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.$store.state.fastCarregando = false;
          this.exibeToasty(e, "error")
        });
    }
  },

  methods: {
    // Não utilizado mais
    async exibePlataformaDetalhes() {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_curso_plataforma/lista_numero_cursos_professores?id_plataforma=" +
              this.$route.params.id_plataforma,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          let objPlataforma = Array.from(json);

          if (objPlataforma.length > 0) {
            resolve(objPlataforma[0]);
          } else {
            reject(false);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
  },
};
</script>

<style scope>
</style>
